<template>
  <div>
    <call-notification-modal
      v-if="showCallNotification"
      :bus="bus"
      :number-from="numberFrom"
      :number-to="numberTo"
      :call-info="callInfo"
      :virtual-phone-id="virtualPhoneId"
      :incoming-call="showCallNotification"
      :contact-name="contactName"
      :phone-name="phoneName"
      @hideNotification="hideCallNotification"
    />

    <sms-notification-modal
      v-if="showSmsNotification"
      :bus="bus"
      :number-from="numberFrom"
      :number-to="numberTo"
      :virtual-phone-id="virtualPhoneId"
      :contact-name="contactName"
      :phone-name="phoneName"
      @hideNotification="hideSmsNotification"
    />
  </div>
</template>

<script>
import CallNotificationModal from "@/components/shared/CallNotificationModal.vue"
import SmsNotificationModal from "@/components/shared/SmsNotificationModal.vue"
import { mapActions, mapGetters } from "vuex"
import Vue from 'vue'
import { connectWithAccountId } from '@/common/modules/actionCable'

export default {
  name: 'PhoneNotification',
  components: { CallNotificationModal, SmsNotificationModal },
  channels: {
    PhoneNotificationChannel: {
      async received(data) {
        if(data.message.event === 'incoming_call') {
          this.incomingCallEvent(data)
        } else if(data.message.event === 'update_notification_count') {
          this.updateNotificationCountEvent()
        } else if (data.message.event === 'call_accepted') {
          this.callAcceptedEvent()
        } else if(data.message.event === "incoming_sms") {
          await this.incomingSmsEvent(data)
        } else {
          this.clearIncomingCallData()
        }
      },
    },
  },
  computed: {
    ...mapGetters('virtualPhones', ['virtualPhones']),
  },
  methods: {
    ...mapActions('calls', ['setIncomingCallId']),
    ...mapActions('bandwidthWebRtc', ['setWebRtcToken']),
    ...mapActions('virtualPhones', ['loadVirtualPhones', 'fetchPhoneNotificationCount']),
    ...mapActions('chatRooms', ['setCurrentChatRoom']),

    hideCallNotification() {
      this.showCallNotification = false
    },
    hideSmsNotification() {
      this.showSmsNotification = false
    },
    hasVirtualPhoneService() {
      return this.virtualPhonesLoaded && this.virtualPhones && this.virtualPhones.length > 0
    },
    virtualPhonesLoaded() {
      return this.$store.getters['virtualPhones/virtualPhonesFinished']
    },
    incomingCallEvent(data) {
      this.callInfo       = data.message.callInfo
      this.numberFrom     = data.message.numberFrom
      this.numberTo       = data.message.numberTo
      this.virtualPhoneId = data.message.virtualPhoneId
      this.contactName    = data.message.contactName
      this.phoneName      = data.message.phoneName
      this.ringtone       = data.message.ringtone

      this.setIncomingCallId(this.callInfo.external_call_id)
      this.setWebRtcToken(this.callInfo.participant_token)

      this.showCallNotification = true
    },
    updateNotificationCountEvent() {
      this.fetchPhoneNotificationCount()
      this.loadVirtualPhones()
    },
    callAcceptedEvent() {
      this.hideCallNotification()
    },
    async incomingSmsEvent(data) {
      this.numberFrom     = data.message.from
      this.numberTo       = data.message.to
      this.virtualPhoneId = data.message.virtualPhoneId
      this.contactName    = data.message.contact_name
      this.phoneName      = data.message.phoneName

      this.showSmsNotification = true
    },
    clearIncomingCallData() {
      this.callInfo       = null
      this.numberFrom     = null
      this.numberTo       = null
      this.virtualPhoneId = null

      this.setIncomingCallId(null)
      this.setWebRtcToken(null)

      this.showCallNotification = false
    },
  },
  watch: {
    showCallNotification() {
      if(this.showCallNotification === true && this.ringtone !== 'disabled'){
        this.callRingtone = new Audio(this.ringtone)
        this.callRingtone.loop = true
        this.callRingtone.muted = false
        this.callRingtone.play()
      } else {
        if(this.callRingtone){
          this.callRingtone.pause()
        }
      }
    },
  },
  async mounted() {
    if(this.hasVirtualPhoneService()) {
      if(!this.$cable._isReset) {
        await connectWithAccountId(this.$cable)
      }
      this.$cable.subscribe({
        channel: 'PhoneNotificationChannel',
      })
    }
  },

  data () {
    return {
      bus: new Vue(),
      callInfo: null,
      showCallNotification: false,
      showSmsNotification: false,
      numberFrom: null,
      numberTo: null,
      virtualPhoneId: null,
      contactName: null,
      phoneName: null,
      callRingtone: null,
      ringtone: 'disabled',
    }
  },
}
</script>
