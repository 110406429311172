<template>
  <b-modal
    id="notification-modal"
    ref="sms-notification-modal"
    size="md"
    hide-footer
    hide-header
    hide-backdrop
    class="modal-md pull-right"
  >
    <br>
    <p class="d-flex flex-column justify-content-center align-items-center phone-number phone-number-from">
      <span class="title-from">Message from  &nbsp;</span>
      {{ formatPhoneNumber(numberFrom) }}
    </p>
    <span v-if="contactName" class="d-flex justify-content-center person-name">{{ contactName }}</span>
    <p class="d-flex justify-content-center phone-number">
      <span class="title-to">To &nbsp;</span>
      {{ formatPhoneNumber(numberTo) }}
    </p>
    <span v-if="phoneName" class="d-flex justify-content-center phone-name">{{ phoneName }}</span>
    <div class="call-buttons mt-2" style="display: flex; flex-flow: row nowrap; justify-content: space-around;">
      <b-button class="float-left btn-lg" variant="danger" type="submit" @click="clickHandlerHide()">
        Dismiss
      </b-button>
      <b-button class="float-right btn-lg" variant="success" type="submit" @click="clickHandler()">
        <fa-icon icon="sms" />
        View
      </b-button>
    </div>
  </b-modal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { contactNameOrPhoneNumber } from '@/helpers'
import { generateFriendlyNumber } from '@/common/modules/formatters'

export default {
  name: 'SmsNotificationModal',
  props: {
    numberFrom: {
      type: String,
      default: '',
    },
    numberTo: {
      type: String,
      default: '',
    },
    virtualPhoneId: {
      type: String,
    },
    bus: {
      type: Object,
    },
    contactName: {
      type: String || null,
    },
    phoneName: {
      type: String || null,
    },
  },
  computed: {
    ...mapGetters('chatRooms', ['currentChatRoom']),
    ...mapGetters('account', ['people']),
  },
  methods: {
    ...mapActions('chatRooms', ['setCurrentChatRoom']),
    ...mapActions('virtualPhones', ['setSelectedPhoneId', 'loadVirtualPhones']),

    async clickHandler() {
      await this.setCurrentChatRoom({
        phoneNumber: this.numberFrom,
        virtualPhoneId: this.virtualPhoneId,
      })

      await this.setSelectedPhoneId(this.virtualPhoneId)
      await this.loadVirtualPhones()

      this.$emit('hideNotification')

      if (this.$route.name !== 'sms-chat') {
        const chatRoomId = this.currentChatRoom?.id
        await this.$router.push({ name: 'sms-chat', params: { currentChatRoomId: chatRoomId } })
      }
    },
    async clickHandlerHide() {
      this.$emit('hideNotification')
    },
    formatPhoneNumber(str) {
      return contactNameOrPhoneNumber(this.people, str.toString().substring(2), generateFriendlyNumber(str.toString()))
    },
    contactNameOrPhoneNumber: contactNameOrPhoneNumber,
    generateFriendlyNumber: generateFriendlyNumber,
  },
  mounted() {
    this.$refs['sms-notification-modal'].show()

    setTimeout(() => {
      this.$emit('hideNotification')
    }, 8000)
  },
}
</script>

<style lang="scss">
#notification-modal {
  width: 400px;
  min-width: 400px;
  z-index: 1000;
  right: 10px;
  top: 80px;
  left: initial;
  .modal-content{
    border: none;
    .modal-body{
      background-color: #000864;
      border-radius: 26px;
    }
  }
  .phone-number{
    color: #ffffff;
    font-weight: 900;
    margin-bottom: .2em;
    &.phone-number-from{
      font-size: 1.5em;
    }
    .title-from{
      font-weight: 600;
      margin-right: 3px;
    }
  }
  .person-name, .phone-name{
    color: #ffffff;
    font-size: 1em;
    margin-right: 2px;
    &.person-name{
      font-size: 1.2em;
      margin-bottom: .5em;
    }
  }
  .hidden-button{
    color: #ffffff;
    &:hover{
      color: #ffffff;
      text-decoration: none;
    }
  }
  .btn{
    border-radius: 20px;
    padding-left: 20px;
    .svg-inline--fa{
      font-size: 1em;
      margin-right: 5px;
      &.gly-rotate {
        font-size: 1.1em;
        transform: rotate(135deg);
      }
    }
  }
  .call-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
}
</style>

