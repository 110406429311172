var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "sms-notification-modal",
      staticClass: "modal-md pull-right",
      attrs: {
        id: "notification-modal",
        size: "md",
        "hide-footer": "",
        "hide-header": "",
        "hide-backdrop": "",
      },
    },
    [
      _c("br"),
      _c(
        "p",
        {
          staticClass:
            "d-flex flex-column justify-content-center align-items-center phone-number phone-number-from",
        },
        [
          _c("span", { staticClass: "title-from" }, [
            _vm._v("Message from   "),
          ]),
          _vm._v(
            "\n    " + _vm._s(_vm.formatPhoneNumber(_vm.numberFrom)) + "\n  "
          ),
        ]
      ),
      _vm.contactName
        ? _c(
            "span",
            { staticClass: "d-flex justify-content-center person-name" },
            [_vm._v(_vm._s(_vm.contactName))]
          )
        : _vm._e(),
      _c("p", { staticClass: "d-flex justify-content-center phone-number" }, [
        _c("span", { staticClass: "title-to" }, [_vm._v("To  ")]),
        _vm._v("\n    " + _vm._s(_vm.formatPhoneNumber(_vm.numberTo)) + "\n  "),
      ]),
      _vm.phoneName
        ? _c(
            "span",
            { staticClass: "d-flex justify-content-center phone-name" },
            [_vm._v(_vm._s(_vm.phoneName))]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "call-buttons mt-2",
          staticStyle: {
            display: "flex",
            "flex-flow": "row nowrap",
            "justify-content": "space-around",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "float-left btn-lg",
              attrs: { variant: "danger", type: "submit" },
              on: {
                click: function ($event) {
                  return _vm.clickHandlerHide()
                },
              },
            },
            [_vm._v("\n      Dismiss\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "float-right btn-lg",
              attrs: { variant: "success", type: "submit" },
              on: {
                click: function ($event) {
                  return _vm.clickHandler()
                },
              },
            },
            [
              _c("fa-icon", { attrs: { icon: "sms" } }),
              _vm._v("\n      View\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }