var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showCallNotification
        ? _c("call-notification-modal", {
            attrs: {
              bus: _vm.bus,
              "number-from": _vm.numberFrom,
              "number-to": _vm.numberTo,
              "call-info": _vm.callInfo,
              "virtual-phone-id": _vm.virtualPhoneId,
              "incoming-call": _vm.showCallNotification,
              "contact-name": _vm.contactName,
              "phone-name": _vm.phoneName,
            },
            on: { hideNotification: _vm.hideCallNotification },
          })
        : _vm._e(),
      _vm.showSmsNotification
        ? _c("sms-notification-modal", {
            attrs: {
              bus: _vm.bus,
              "number-from": _vm.numberFrom,
              "number-to": _vm.numberTo,
              "virtual-phone-id": _vm.virtualPhoneId,
              "contact-name": _vm.contactName,
              "phone-name": _vm.phoneName,
            },
            on: { hideNotification: _vm.hideSmsNotification },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }