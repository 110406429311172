<template>
  <b-modal
    id="notification-modal"
    ref="call-notification-modal"
    size="md"
    hide-footer
    hide-header
    hide-backdrop
    no-close-on-backdrop
    no-close-on-esc
    class="modal-md pull-right"
  >
    <br>
    <p class="d-flex justify-content-center phone-number phone-number-from">
      <span class="title-from">Call from  &nbsp;</span>
      {{ formatPhoneNumber(numberFrom) }}
    </p>
    <span v-if="contactName" class="d-flex justify-content-center person-name">{{ contactName }}</span>
    <p class="d-flex justify-content-center phone-number">
      <span class="title-to">To &nbsp;</span>
      {{ formatPhoneNumber(numberTo) }}
    </p>
    <span v-if="phoneName" class="d-flex justify-content-center phone-name">{{ phoneName }}</span>
    <div class="call-buttons" style="display: flex; flex-flow: row nowrap; justify-content: space-around;">
      <b-button class="float-left btn-lg" variant="danger" type="submit" @click="clickHandlerHide()">
        <fa-icon icon="phone-alt" class="gly-rotate" />
        Reject
      </b-button>
      <!--      <b-button class="hold-button btn-lg" variant="primary" type="submit" @click="clickHandlerHold()">-->
      <!--        <fa-icon icon="pause"></fa-icon>-->
      <!--        Hold-->
      <!--      </b-button>-->
      <b-button class="float-right btn-lg" variant="success" type="submit" @click="clickHandler()">
        <fa-icon icon="phone-alt" />
        Accept
      </b-button>
    </div>
  </b-modal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CallNotificationModal',
  props: {
    numberFrom: {
      type: String,
      default: '',
    },
    numberTo: {
      type: String,
      default: '',
    },
    callInfo: {
      type: Object,
    },
    virtualPhoneId: {
      type: String,
    },
    bus: {
      type: Object,
    },
    contactName: {
      type: String || null,
    },
    phoneName: {
      type: String || null,
    },
  },
  computed: {
    ...mapGetters('calls', ['incomingCallId', 'isCallAccepted']),
  },
  methods: {
    ...mapActions('calls', ['setIncomingCallId', 'setIsCallAccepted', 'acceptCall', 'routeToVoicemail', 'holdCall', 'setCallOnHold']),
    ...mapActions('bandwidthWebRtc', ['setWebRtcToken']),
    async clickHandler() {
      this.$emit('hideNotification')
      await this.acceptCall(this)
      if (this.isCallAccepted) {
        await this.$router.push({ name: 'call', params: { virtualPhoneId: this.virtualPhoneId, destinationNumber: this.numberFrom } })
      }
    },
    async clickHandlerHide(){
      this.$emit('hideNotification')
      await this.$store.dispatch('calls/routeToVoicemail')
    },
    formatPhoneNumber: function(str) {
      return str.toString().replace(/(.{2})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
    },
  },
  mounted() {
    this.$refs['call-notification-modal'].show()
  },
}
</script>

<style lang="scss">
  #notification-modal {
    width: 400px;
    min-width: 400px;
    z-index: 1000;
    right: 10px;
    top: 80px;
    left: initial;
    .modal-content{
      border: none;
      .modal-body{
        background-color: #000864;
        border-radius: 26px;
      }
    }
    .phone-number{
      color: #ffffff;
      font-weight: 900;
      margin-bottom: .2em;
      &.phone-number-from{
        font-size: 1.5em;
      }
      .title-from{
        font-weight: 600;
        margin-right: 3px;
      }
    }
    .person-name, .phone-name{
      color: #ffffff;
      font-size: 1em;
      margin-right: 2px;
      &.person-name{
        font-size: 1.2em;
        margin-bottom: .5em;
      }
    }
    .hidden-button{
      color: #ffffff;
      &:hover{
        color: #ffffff;
        text-decoration: none;
      }
    }
    .btn{
      border-radius: 20px;
      padding-left: 20px;
      .svg-inline--fa{
        font-size: 1em;
        margin-right: 5px;
        &.gly-rotate {
          font-size: 1.1em;
          transform: rotate(135deg);
        }
      }
    }
    .call-buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
    }
  }
</style>

